.dlab-blog{
	.post-tags{
		display: block!important;
		margin-bottom: 10px;
		a{
			margin-left: 5px;
			font-weight: 400;
			color: inherit;
			&:hover{
				color:var(--primary);	
			}
		}
		strong{
			font-weight:700;	
		}
	}
	&.no-image{
		padding:30px;
		background:var(--rgba-primary-05);
	}
	.dlab-meta{
		margin-bottom: 10px;
		&>ul{
			display: flex;
			flex-wrap: wrap;
			margin-left: -12px;
			margin-right: -12px;
			@include respond('tab-land'){
				margin-left: -6px;
				margin-right: -6px;
			}
			&>li{
				font-weight: 400;
				display: flex;
				align-items: center;
				font-size: 15px;
				padding-left: 12px;
				padding-right: 12px;
				a{
					display:flex;
					align-items:center;
				}
				i{
					font-size: 22px;
					color: var(--primary);
					@include respond('tab-land'){
						font-size: 18px;
						margin-right:5px;
					}
				}
				@include respond('tab-land'){
					font-size: 14px;
					padding-left: 6px;
					padding-right: 6px;
				}
				&.post-comment{
					margin-left: auto;
					padding-right: 5px;
					span{
						margin-left: 10px;
					}
					@include respond('phone'){
						display:none;
					}
				}
				&.post-comment,
				&.post-share{
					
				}
				&.post-author{
					a{
						color: inherit;
						margin-left: 3px;
					}
				}
				&.post-share{
					position: relative;
					ul {
						display: inline-block;
						position: absolute;
						right: 40px;
						background-color: var(--primary);
						box-shadow: -2px 9px 20px 0 rgba(0,0,0,0.2);
						display: flex;
						border-radius: 4px;
						transform: scale(0.8);
						-moz-transform: scale(0.8);
						-webkit-transform: scale(0.8);
						-ms-transform: scale(0.8);
						-o-transform: scale(0.8);
						transition: all 0.5s;
						-moz-transition: all 0.5s;
						-webkit-transition: all 0.5s;
						-ms-transition: all 0.5s;
						-o-transition: all 0.5s;
						z-index: 2;
						opacity: 0;
						padding: 5px;
						visibility: hidden;
						&:after {
							content: "";
							position: absolute;
							right: -7px;
							top: 50%;
							background: var(--primary);
							width: 8px;
							height: 8px;
							display: block;
							transform: rotate(45deg) translateX(-50%);
							-moz-transform: rotate(45deg) translateX(-50%);
							-webkit-transform: rotate(45deg) translateX(-50%);
							-ms-transform: rotate(45deg) translateX(-50%);
							-o-transform: rotate(45deg) translateX(-50%);
						}
						li{
							a {
								width: 30px;
								height: 30px;
								display: block;
								line-height: 30px;
								text-align: center;
								color: $white;
							}
						}
					}
					&:hover{
						ul {
							transform: scale(1);
							-moz-transform: scale(1);
							-webkit-transform: scale(1);
							-ms-transform: scale(1);
							-o-transform: scale(1);
							right: 40px;
							opacity: 1;	
							visibility: visible;
						}
					}
				}
			}
		}
		}
	.dlab-title{
		margin-bottom: 10px;
		line-height: 1.4;
	}
	.dlab-media + .dlab-info{
		padding-top:30px;	
	}
	&.blog-half .dlab-info{
		padding-top:0;	
		align-self: center;
	}
	&.style-1{
		box-shadow: 0px 0 20px 0 rgba(0, 0, 0, 0.15);		
		border-radius: $border-radius;
		overflow: hidden;
		
		.dlab-info{
			padding: 30px;
			@include respond('phone'){
				padding:15px;
			}
			.dlab-meta{
				border-bottom: 1px solid $border-color;
				padding-bottom: 15px;
				margin-bottom: 15px;
			}
			.meta-bottom{
				border-top: 1px solid $border-color;
				border-bottom: 0;
				padding: 25px 0 0;
				margin: 20px 0 0;
				@include respond('phone'){
					padding: 15px 0 0;
				}
			}
		}
	}
	&.blog-half{
		display: flex;
		padding: 30px;
		background-color: $white;
		box-shadow: 1px 0 30px rgba(0, 0, 0, 0.1);
		border-radius: $border-radius;
		@include transitionMedium;
		.dlab-media{
			width: 180px;
			min-width: 180px;
			margin-right: 20px;
			border-radius: $border-radius;
			img{
				width: 100%;
			}
		}
		.dlab-title {
			margin-bottom: 5px;
			line-height: 1.3;
		}
		p{
			margin-bottom:10px;
			font-size:15px;
			line-height:1.5;
		}
		.dlab-meta{
			margin-bottom:0;
			line-height: 1;
		}
		@include respond('phone'){
			display: block;
			padding: 20px;
			.dlab-media{
			    width: 100%;
				min-width: 100%;
				margin-right: 0;
				margin-bottom: 20px;
			}
		}
	}
}
.post-carousel.owl-theme{
	position:relative;
	.owl-dots{
		position:absolute;
		bottom:20px;
		width: 100%;
		.owl-dot{
			span{
				background: transparent;
				width: 12px;
				height: 12px;
				border: 2px solid #fff;
			}
			&.active span{
				background: var(--primary);
				border: 2px solid var(--primary);
			}
		}
		
	}
	.owl-nav{
		margin:0;
		.owl-prev,
		.owl-next{
			@include transitionMedium;
			background: rgba(0,0,0,0.2);
			color: #fff;
			border-radius: 4px;
			font-size: 22px;
			&:hover{
				background:var(--primary);
			}
		}
	}
}
.post-video{
	position:relative;
	a{
		display:block;	
		position:relative;
	}
	.post-video-icon{
	    width: 80px;
		height: 80px;
		position: absolute;
		top: 50%;
		left: 50%;
		background: #fff;
		@include translateZ50;
		@include transitionMedium;
		font-size: 30px;
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
		padding-left: 5px;
		border-radius: 4px;
		&:hover{
			background:var(--primary);
			color:#fff;
		}
	}
}
.post-quote{
	padding:50px;
	background:var(--rgba-primary-05);
	padding-left: 120px;
	position:relative;
	.post-quote-icon{
		position: absolute;
		width: 50px;
		left: 40px;
		transform: rotate(180deg);
		-moz-transform: rotate(180deg);
		-webkit-transform: rotate(180deg);
		
		svg{
			fill:var(--primary);
		}
	}
	.dlab-title{
		margin-bottom:0;
	}
	&.dlab-blog.style-1,
	&.dlab-blog.style-2{
		.dlab-info{
			padding:0;	
		}	
	}
}























