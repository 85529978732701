// FILTER BUTTONS CSS
.site-filters {
    margin-bottom: 30px;
	ul {
		margin: 0;
		list-style: none;
	}
	li {
		display: inline-block;
		padding: 0;
		margin-bottom:3px;
		&.btn {
			box-shadow: none;
			-webkit-box-shadow: none;
			outline: none !important;
		}
		input {
			display: none;
		}
		a {
			margin: 0 5px 5px 0;
		}
		&.active{
			[class*="btn"] {
				color: #fff;
				background-color: #EFBB20;
			}
		}
	}
	&.center{
		text-align: center;
		ul {
			display: inline-block;
			margin: auto;
		}
		[class*="btn"] {
			display: inline-block;
			margin: 0 5px 10px;
			text-transform: uppercase;
			@include respond('phone'){
				margin: 0 1px 5px;
				padding: 5px 10px;
				font-size: 12px;
			}
		}
	}
}
// Filter
.site-filters{
	&.style-1{
		.filters{
			justify-content: center;
			li{
				margin: 0;
				padding: 0;
				a{
					font-size: 15px;
					color: #5e5e5e;					
					padding: 6px 25px;
					border-radius: 30px;					
					display: block;
					margin: 0;
					text-transform: capitalize;
					font-family: $font-family-title;
					@include transitionMedium;
				}
				&.active{
					a{
						background-color: var(--primary);
						box-shadow: 0 2px 15px var(--rgba-primary-5);
						color: $white;
					}
				}
			}
		}
		@include respond('tab-land'){
			.filters{
				li{
					a{
						font-size: 16px;
						padding: 10px 25px;
					}
				}
			}
		}
		@include respond('tab-port'){
			.filters{
				display: block;
				li{
					margin: 0;
					a {
						font-size: 14px;
						padding: 8px 18px;
					}
				}
			}
		}
		@include respond('phone-land'){
		}
		@include respond('phone'){
			background-color: transparent;
			padding: 0;
			.filters{
				li{
					margin: 0;
					a{
						padding: 8px 12px;
					}
				}
			}
		}
	}
	
	// style 2
	&.style-2{
		.filters{
			li{
				margin: 0 15px 10px;
				a{
					border-bottom: 3px solid;
					border-color: transparent;
					font-size: 15px;
					color: #636363;
					text-transform: capitalize;
					margin: 0;
					padding: 0 0 5px;
					font-weight:400;
				}
				&.active{
					a{
						border-color: var(--primary);
						color: var(--primary);
					}
				}
			}
		}
		@include respond('phone'){
			.filters{
				li{
					margin: 0 5px 5px;
					a{
						font-size: 14px;
						border-bottom: 2px;
					}
				}
			}
		}
	}
	
	// style 2
	&.style-3{
		.filters{
			li{
				margin:0;
				padding: 0;
				a{
					font-size: 15px;
					color: $secondary;
					text-transform: capitalize;
					background-color: $primary-light;
					padding: 7px 25px;
					border-radius: 30px;
					display: block;
					margin: 0 5px 5px 5px;
					@include respond('tab-land'){
						padding: 7px 15px;
					}
				}
				&.active{
					a{
						background-image: var(--gradient-sec);
						background-size:200%;
						color: $white;
						box-shadow: 0 2px 15px rgba(#1f2471, 0.5);
					}
				}
			}
		}
		@include respond('phone-land'){
			.filters{
				li{
					margin: 0;
					a{
						font-size: 13px;
						padding: 8px 15px;
						margin-left:0;
						margin-right:0;
					}
				}
			}
		}
	}
}