// OWL SLIDER ================
// NEXT PRE BTN
.owl-none .owl-nav{
	display:none;
}
.owl-nav{
	.owl-prev, 
	.owl-next{
		cursor: pointer;
		transition: all 0.5s;
		display: inline-block;
	}
}
.owl-theme{
	.owl-nav,
	.owl-dots{
		margin-top: 20px;
		text-align: center;
	}
	.owl-nav .disabled {
		opacity: .5;
		cursor: default
	}
	.owl-dots{
		text-align:center;
		
		.owl-dot {
			display: inline-block;
			span {
				width: 8px;
				height: 8px;
				margin: 5px 7px;
				background: #d6d6d6;
				display: block;
				-webkit-backface-visibility: visible;
				-webkit-transition: opacity 200ms ease;
				-moz-transition: opacity 200ms ease;
				-ms-transition: opacity 200ms ease;
				-o-transition: opacity 200ms ease;
				transition: opacity 200ms ease;
				-webkit-border-radius: 30px;
				-moz-border-radius: 30px;
				border-radius: 30px
			}
			&:hover,
			&.active{
				span{
					background: #869791
				}
			}
		}
	}
	&.dots-style-1{
		.owl-dots .owl-dot{
			span{
				background: rgba(0,0,0,0.2);
				width: 8px;
				height: 8px;
				margin: 4px;
			}
			&:hover,
			&.active{
				span{
					background: #5c2782;
				}
			}
		}
	}
}
// OWL BUTTON TOP TO CENTER
.owl-btn-top-c .owl-nav {
    text-align: center;
}

// OWL BUTTON TOP TO LEFT
.owl-btn-top-l .owl-nav {
    text-align: left;
	
	.owl-prev {
		margin-left: 0;
	}
}
// OWL BUTTON TOP TO RIGHT
.owl-btn-top-r .owl-nav {
    text-align: right;
	.owl-next {
		margin-right: 0;
	}
}
// OWL BUTTON TOP TO LEFT & RIGHT
.owl-btn-top-lr .owl-nav{
    text-align: left;
	.owl-next {
		margin-right: 0;
		float: right;
	}
}
.owl-btn-full .owl-prev, 
.owl-btn-full .owl-next{
	padding: 40px 8px;
}
// OWL BUTTON < > CENTER TO LEFT & RIGHT
.owl-btn-center{
	.owl-nav{
		justify-content: center;
	}
}
.owl-btn-center-lr {
	.owl-prev,
	.owl-next{
		position: absolute;
		left: 0;
		top: 50%;
		margin: 0;
		transform: translateY(-50%);
		-o-transform: translateY(-50%);
		-moz-transform: translateY(-50%);
		-webkit-transform: translateY(-50%);
		cursor:pointer;
	}
	.owl-prev {
		left: 0;
	}
	.owl-next {
		left: auto;
		right: 0;
	}
}
.owl-carousel{
	cursor: grab;
	.owl-item img {
		transform-style: inherit;
	}
}

// OWL BUTTON STYLE
.owl-btn-1{
	.owl-nav{
		.owl-prev, 
		.owl-next{
			border-radius: 3px;
			height: 40px;
			line-height: 40px;
			margin: 0 -70px !important;
			padding: 0;
			text-align: center;
			width: 40px;
			@include respond('laptop'){
				margin: 0 -5px !important;
			}
		}
	}
	&.owl-btn-gradient{
		.owl-nav{
			.owl-prev, 
			.owl-next{
				background-image: var(--gradient);
				background-size:200%;
				color:#fff;
			}
		}	
	}
}
.owl-btn-2{
	.owl-nav{
		.owl-prev, 
		.owl-next{
			border-radius: 50px;
			height: 45px;
			line-height: 45px;
			margin: 0 10px !important;
			padding: 0;
			text-align: center;
			width: 45px;
		}
	}
}
.owl-btn-3{
	.owl-nav{
		.owl-prev, 
		.owl-next{
			border-radius: 3px;
			line-height: 40px;
			margin: 0 10px !important;
			padding: 0;
			text-align: center;
			margin: 0 -70px !important;
			background-color: rgba(0, 0, 0, 0);
			color: #000000;
			font-size: 35px;
			height: auto;
			width: auto;
			opacity:0.2;
			&:hover {
				background-color: rgba(0, 0, 0, 0);
				color:#000;
				opacity:1;
			}
			@include respond('tab-port'){
				margin:0 10px !important;
			}
		}
	}
	&.btn-white{
		.owl-nav{
			.owl-prev, 
			.owl-next{
				color:#fff;
				&:hover {
					color:#fff;
				}
			}
		}
	}
}
.owl-btn-4 {
	overflow:hidden;
	.owl-nav{
		.owl-prev, 
		.owl-next{
			background: #ffffff none repeat scroll 0 0;
			border-radius: 30px;
			box-shadow:0 0 20px 7px rgba(0, 0, 0, 0.5);
			color: #000000;
			font-size: 24px;
			height: 50px;
			line-height: 50px;
			margin: 0 -10px !important;
			overflow: hidden;
			padding: 0;
			width: 50px;
			opacity:0.7;
			&:hover {
				background: #ffffff none repeat scroll 0 0;
				color: #000;
				opacity:1;
			}
		}
	}
}
.owl-btn-primary{
	.owl-prev, 
	.owl-next{
		background-color: var(--primary);
		color: $white;
		transition: all 0.5s;
		&:hover{
			background-color: var(--primary-hover);
			color:$white;
		}
	}
}
.owl-btn-white{
	.owl-prev, 
	.owl-next{
		background-color: $white;
		color:var(--primary);
		transition: all 0.5s;
		&:hover{
			background-color: var(--primary);
			color: $white;
		}
	}
}

// OWL-DOTS
.owl-dots-none .owl-dots{
	display:none;
}
.owl-dots-white-full,
.owl-dots-black-full,
.owl-dots-primary-full{
	.owl-dots{
		.owl-dot{
			span{
				@include transitionMedium;
				margin:4px;
				background:#b8b8b8;
			}
			&:active,
			&.active{
				span{
					opacity:1;
					background-color:var(--primary);
					width:38px;
				}
			}
		}
	}
}
.owl-dots-white-big,
.owl-dots-black-big,
.owl-dots-primary-big{
	.owl-dots{
		.owl-dot{
			span{
				@include transitionMedium;
				margin:4px;
			}
			&:active{
				span{
					width:14px;
					height:14px;
					opacity:1;
					margin:0px 4px 2px 4px;
				}
			}
		}
	}
}
.owl-dots-black-full .owl-dots .owl-dot span,
.owl-dots-black-big .owl-dots .owl-dot span{
	background-color:#333;
}
.owl-dots-white-full .owl-dots .owl-dot span,
.owl-dots-white-big .owl-dots .owl-dot span{
	background-color:#f5f5f5;
}

/* Owl Btn Shadow */
.owl-btn-shadow{
	.owl-prev, 
	.owl-next{
		box-shadow: 1px 0 20px rgba(0, 0, 0, 0.1);
	}
}