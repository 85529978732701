// TESTIMONIALS
.testimonial-pic {
    background: #FFF;
    width: 100px;
    height: 100px;
    position: relative;
    display: inline-block;
    border: 5px solid #FFF;
	&.radius {
		border-radius: 100%;
		-webkit-border-radius: 100%;
		img {
			width: 100%;
			height: 100%;
			border-radius: 100%;
			-webkit-border-radius: 100%;
		}
	}
	&.shadow {
		-webkit-box-shadow: 2px 3px 6px -3px rgba(0, 0, 0, 0.35);
		-moz-box-shadow: 2px 3px 6px -3px rgba(0, 0, 0, 0.35);
		box-shadow: 2px 3px 6px -3px rgba(0, 0, 0, 0.35);
	}
}
.quote-left{
	position: relative;
	&:before {
		font-family: "FontAwesome";
		position: absolute;
		z-index: 10;
		top: 15px;
		content: "\f10d";
		left: 0;
	}
}
.quote-right{
	position: relative;
	&:before {
		font-family: "FontAwesome";
		position: absolute;
		z-index: 10;
		top: 15px;
		content: "\f10e";
		right: 0;
	}
}
.testimonial-text {
    padding: 15px;
    position: relative;
	font-size:18px;
	font-weight: 400;
	font-style: italic;
	
	p:last-child {
		margin: 0;
	}
}
.testimonial-detail {
    padding: 5px;
}
.testimonial-name{
    font-size: 22px;
    font-weight: 800;
	font-family: $font-family-title;
	color: #000;
	line-height: 26px;
	margin-bottom: 5px;
	@include respond('phone'){
		font-size: 18px;
		line-height: 20px;
		margin-bottom: 2px;
	}
}
.testimonial-position {
    font-size: 15px;
	color:var(--primary);
	@include respond('phone'){
		font-size: 13px;
	}
}
.testimonial-name,
.testimonial-position {
    display: block;
}
.testimonial-bg {
    color: #fff;
}

// CLIENT BOX
.client-box{
	.owl-theme{
		.owl-dots{
			text-align: center;
		}
	}
}
.client-says{
	padding-left:180px;
	position:relative;
	@include respond('phone-land'){
		padding-left:0;
	}
	.testimonial-detail{
		padding:0;
	}
	.testimonial-pic{
		width: 150px;
		height: 150px;
		border: 0;
		position: absolute;
		left: 0;
		top: 0;
		@include respond('phone-land'){
			width: 70px;
			height: 70px;
			position: unset;
			margin-bottom:15px;
			text-align: center;
		}
	}
	.testimonial-text {
		padding: 0;
		p{
			position: relative;
			font-size:18px;
			line-height:28px;
			color:#fff;
			font-weight: 400;
			margin-bottom: 20px;
			@include respond('phone-land'){
				font-size:15px;
				line-height:24px;
			}
		}
	}
	.testimonial-name{
		color:var(--primary);
		font-size: 22px;
		text-transform: capitalize;
		font-weight: 600;
	}
	.testimonial-position {
		font-size: 14px;
		font-style: inherit;
		text-transform: capitalize;
		color: #999;
	}
}
.testimonial-two{
	.client-says{
		padding-left: 130px;
		
		.testimonial-pic {
			width: 90px;
			height: 90px;
		}
		.testimonial-text p{
			font-size: 16px;
		}
		.testimonial-name{
			font-size: 20px;
		}
		.testimonial-position {
			font-size: 13px;
			color: #868686;
		}
	}
}
.testimonial-two-dots-bx.owl-theme{
	.owl-dots .owl-dot{
		span{
			height: 13px;
			width: 13px;
			background-color: #fff;
			@include transitionMedium;
			opacity:1;
		}
		&.active span{
			@include transformScale;
			background-color:var(--primary);
			width: 13px;
		}
	}
}

// TESTIMONIAL 1
.testimonial-1 {
	background-color: $white;
	border-radius: $border-radius;
    padding: 35px;
	box-shadow: 1px 0 20px rgba(0, 0, 0, 0.1);
	position: relative;
	
	@include respond('phone'){
		padding: 20px;
	}
	.testimonial-text{
		padding: 0 0 20px;
		p{
			font-size: 18px;
			@include respond('phone'){
				font-size: 15px;
			}
		}
	}
	&:before{
		content: "\f10d";
		left: 30px;
		bottom: 30px;
		position: absolute;
		font-family: 'Font Awesome 5 Free';
		font-weight: 700;
		font-size: 85px;
		color: var(--primary);
		opacity: 0.4;
		line-height: 1;
		@include respond('phone'){
			font-size: 85px;
			bottom:20px;
			left:20px;
		}
	}
	.testimonial-pic{
		border-radius: 50%;
		height: 80px;
		width: 80px;
		border: 5px solid;
		border-color: var(--primary);
		overflow: hidden;
		margin-left: 20px;
		@include respond('phone'){
			height: 60px;
			width: 60px;
			margin-left: 10px;
		}
	}
	.star-rating{
		li{
			font-size: 18px;
			margin: 0 2px;
		}
	}
	.testimonial-detail{
		padding: 0;
		display: flex;
		align-items: center;
		text-align: right;
		flex-direction: row-reverse;
	}
}
.owl-carousel .testimonial-1 {
	margin: 15px;
}

// testimonial 2 
.testimonials-carousel2{
	margin-left: -15px;
    margin-right: -15px;
	width: auto;
		
	.testimonial-2{
		margin-top: 25px;
		margin-bottom: 25px;
		margin-left: 15px;
		margin-right: 15px;
	}
}
.testimonial-2 {
	position: relative;
	background-color: $white;
	border-radius: $border-radius;
	padding: 40px 40px 40px 45px;
	overflow: hidden;
	box-shadow: 1px 0 20px rgba(0,0,0,0.1);
	
	@include respond('phone'){
		padding: 20px 20px 20px 25px;
	}
	&:before{
		content: "";
		width: 18px;
		left: 0;
		top: 0;
		height: 100%;
		background-image: var(--gradientY);
		position: absolute;
		@include respond('phone'){
			width: 10px;
		}
	}
	&:after{
		content: "\f10d";
		left: 35px;
		top: 60px;
		position: absolute;
		font-family: 'Line Awesome Free';
		font-size: 120px;
		color: var(--primary);
		opacity: 0.4;
		line-height: 55px;
		font-weight: 800;
		@include respond('phone'){
			font-size: 80px;
			left: 20px;
			top: 25px;
		}
		
	}
	.testimonial-pic{
		margin-left: 15px;
		border: 5px solid #fff;
		border-radius: 50%;
		height: 90px;
		width: 90px;
		overflow: hidden;
		box-shadow: 2px 0 30px rgba(0,0,0,0.1);
		&:before{
			right: 10px;
			font-size: 50px;
			top: auto;
			bottom: -30px;
		}
		@include respond('phone'){
			height: 60px;
			width: 60px;
			border: 2px solid #fff;
		}
	}
	.testimonial-detail{
		display: flex;
		padding: 0;
		align-items: center;
		margin-bottom: 25px;
		flex-direction: row-reverse;
		text-align: right;
		@include respond('phone'){
			margin-bottom: 15px;
		}
	}
	.testimonial-position{
		text-transform: uppercase;
	}
	.testimonial-name{
		font-size: 20px;
		font-weight: 800;
		margin-bottom: 5px;
		@include respond('phone'){
			font-size: 18px;
			font-weight: 700;
			margin-bottom: 3px;
		}
	}
	.testimonial-text{
		font-size: 18px;
		padding: 0;
		color: inherit;
	}
	@include respond('phone'){
		
		.testimonial-text {
			padding: 0;
			font-size: 16px;
		}
	}
	
}

// testimonial 3 
.testimonial-3{
	text-align: center;
	width: 750px;
	margin: auto;
	.testimonial-pic{
		width: 170px;
		height: 170px;
		border: 2px solid var(--primary);
		border-radius: 50%;
		position: relative;		
		img{
			width: 125px;
			height: 125px;
			box-shadow: 0 0px 10px 4px rgba(31, 66, 135, 0.1);
			border-radius: 50%;
			position: absolute;
			top: 50%;
			transform: translate(-50%, -50%);
			left: 50%;
		}
	}
	@include respond('tab-port'){
		width: 100%;
	}
	@include respond('phone-land'){
		.testimonial-pic{
			width: 140px;
			height: 140px;		
			img{
				width: 100px;
				height: 100px;
			}
		}
	}
}