* {
    outline: none;
    padding: 0;
    
    &::after {
        margin : 0;
        padding: 0;
    }
    &::before {
        margin : 0;
        padding: 0;
    }
}
body {
    line-height: 1.6;
	color:#5e5e5e;
	font-size: 16px;
    &.fixed {
        overflow: hidden;
        position: fixed;
    } 
}
h1 a, 
h2 a, 
h3 a, 
h4 a, 
h5 a, 
h6 a{
	color: #070120;
}
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6{
	font-family:$font-family-title;
	font-weight:$headings-font-weight;
	color:#070120;
}
ul {
    padding: 0;
    margin : 0;
}
img {
    border-style: none;
    height: auto;
    max-width: 100%;
    vertical-align: middle;
}
li {
    list-style: none;
}
a {
    // color       : $td;
    text-decoration: none;
    outline        : none;
	color          : var(--primary);	
	@include transitionMedium;	
    &:hover,
    &:focus,
    &.active {
        text-decoration: none;
        outline        : none;
		color:var(--primary-hover);
    }
}
// COLOR
.text-primary{
	color:var(--primary) !important; 	
}
.bg-primary {
    background-color: var(--primary) !important;
}
.bg-gray{
	
}
b,
strong,
.strong {
    font-weight: 500;
    color      : $headings-color;
}

.h-0 {
    height: 0;
}

#main-wrapper {
    opacity       : 0;
    transition    : all 0.25s ease-in;
    overflow      : hidden;
    position      : relative;
    z-index       : 1;
    // height     : 100vh;
    // padding-top: 75px;
    margin-top    : 60px;

    &.show {
        opacity: 1;
    }
}

.content-body {
    margin-left  : 65px;
    margin-top   : 18px;
    margin-right : 6px;
    margin-bottom: 45px;
}

h4, 
.h4 {
	@include respond('tab-land'){
		font-size: 1.25rem;
	}
}
h3, 
.h3 {
	@include respond('tab-land'){
		font-size: 1.5rem;
	}
}
h2, 
.h2 {
	@include respond('tab-land'){
		font-size: 1.75rem;
	}
}
.container {
    // max-width: 1500px;
}

.bg-gray{
	background-color: #f8f5ff;
}	
// Selection
::selection {
	color: $white;
	background: var(--primary);
}