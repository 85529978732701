.site-footer{
	&.style-3{
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		background-color: var(--primary);
		.footer-top{
			background: transparent;
		}
	}
}