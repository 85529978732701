.blockquote{
	position: relative;
	margin: 30px 0;
	&:before{
		content: "\f11f";
		position: absolute;
		font-family: Flaticon;
	}
	&.style-1{
		padding: 0 0 0 60px;
		box-shadow: none;
		background: transparent;
		
		&:before{
			color: var(--primary);
			left: 0;
			top: 50%;
			font-size: 42px;
			transform: translateY(-50%);
			width: 50px;
			padding: 5px;
			opacity: 1;
			height: 40px;
		}
		.blockquote-content{
			font-size: 22px;
			line-height: 32px;
			margin-bottom: 0;
			border-left: 3px solid $primary-light;
			padding-left: 15px;
		}
	}
	&.style-2{
		padding: 20px 30px;
		box-shadow: none;
		background: transparent;
		overflow: unset;		
		&:before{
			color: var(--primary);
			left: 30px;
			font-size: 36px;
			opacity: 0.3;
			z-index: -1;
			top: 5px;
			width: 40px;
			height: 40px;
		}
		&:after{
			content: "";
			width: 10px;
			height: 100%;
			top: 0;
			left: 0;
			position: absolute;
			background-image: var(--gradientY);
			border-radius: 30px;
		}
		.blockquote-content{
			font-size: 18px;
			line-height: 28px;
			margin-bottom: 0;
		}
	}
	&.style-3{
		padding: 20px 30px 20px 80px;
		background-color: #f8f5ff;
		text-align: center;
		box-shadow:none;
		
		&:before{
			background: var(--gradient);
			background-size: 200%;
			left: 20px;
			top: 50%;
			font-size: 40px;
			transform: translateY(-50%);
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
			width: auto;
			height: auto;
			opacity: 1;
			overflow: unset;
			line-height: 1;
			padding: 5px;
		}
		.blockquote-content{
			font-size: 18px;
			line-height: 28px;
			margin-bottom: 0;
		}
	}
}