#loading-area {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 999999999;
	background-position:center;
	background-repeat:no-repeat;
	background-size:80px;
}
#loading-area.loading-01{
	background-color:#f4f2ff;
	background-image:url(./../../images/loading-01.svg);
}
#loading-area.loading-02{
	background-color:#fef9f2;
	background-image:url(./../../images/loading-02.svg);
	background-size:60px;
}
#loading-area.loading-03{
	background-color:#fff;
	background-image:url(./../../images/loading-03.svg);
	background-size:60px;
}