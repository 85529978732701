ul{
	&[class*=list-] {
		font-size: 15px;
		li{
			position: relative;
			padding: 8px 5px 8px 35px;
			&:before{
				position: absolute;
				left: 0;
				top: 50%;
				transform: translateY(-50%);
			}
		}
	}
	&.list-check{
		li{
			&:before{
				content: "\f120";
				font-family: Flaticon;
				font-size: 24px;
			}
		}
		&.style-1{
			li{
				padding: 8px 5px 8px 28px;
				&:before{
					content: "\f058";
					font-family: 'Line Awesome Free';
					font-size: 24px;
				}
			}
		}
		&.style-2{
			li{
				padding: 8px 5px 8px 28px;
				&:before{
					content: "\f058";
					font-family: 'Line Awesome Free';
					font-size: 24px;
				}
			}
		}
	}
	&.list-check-2{
		li{
			padding: 10px 5px 10px 48px;
			font-size: 20px;
			
			@include respond('tab-land'){
				font-size: 16px;
			}
			
			&:before{
				content: "\f00c";
				font-family: 'Line Awesome Free';
				font-size: 24px;
				width: 35px;
				height: 35px;
				background: #000;
				text-align: center;
				line-height: 35px;
				color: #fff;
				border-radius: 35px;
				font-weight: 900;
				background-image: var(--gradient);
				background-size: 200%;
			}
		}
	}
	&.list-arrow{				
		li{
			&:before{
				content: "\f101";
				font-family: 'Line Awesome Free';
				font-weight: 600;
				font-size: 20px;
				@include transitionMedium;
			}
			&:hover{
				&:before{
					left: 10px;
				}
			}
		}
	}
	&.primary{
		li{
			&:before{
				color: var(--primary);
			}
		}
	}
}