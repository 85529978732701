.table-responsive-sm {
    min-width: 48rem;
}

.table {
    // border-collapse: separate;
    // border-spacing : 0 1rem;
    // line-height    : 1;
    margin-bottom     : 0px;
    // font-family    : $barlow;
    font-weight       : 400;

    tr {

        td,
        th {
            // border           : 0px;
            // vertical-align   : middle;
            // padding       : 5px 7px;
            padding          : 15px;

            // &:last-child {
            //     text-align: right;
            // }
        }
    }
}

.table {
    tr {
        th {
            // padding     : 0px 5px;
            color       : $headings-color;
            font-weight : 500;
            // font-size: 14px;
        }
    }
}

.table-striped {
    thead {
        tr {
            th {
                padding-bottom: 15px!important;
            }
        }
    }

    tbody tr:nth-of-type(#{$table-striped-order}) {
        background-color: $body-bg;
    }

    tfoot{
        border-top: 2px solid $border-color;
        tr{
            th{
                padding-top: 10px;
            }
        }
    }
}