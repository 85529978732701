.site-footer{
	&.style-2{
		background-repeat: no-repeat;
		background-position: center;
		position: relative;
		height: auto !important;
		background-color: #0e1b1f;
		background-size: contain;
		z-index:1;
		&:before{
			content: "";
			background-image: url(/assets/images/pattern/pattern2.png);
			background-position: left top;
			background-repeat: no-repeat;
			background-size: contain;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index:-1;
		}
		.dlab-social-icon li a{
			background-color: rgba(255,255,255,0.1);
			color:#fff;
		}
		.footer-top{
			background: transparent;
			padding-top: 300px;
		}
		.container + {
			.footer-top{
				padding-top: 70px;
			}
		}
		.footer-bottom{
			background-color: #1f343b;
			padding: 10px 0;
		}
		@include respond('tab-land'){
			.footer-top{
				.widget_services{
					padding-left: 0;
				}
			}
		}
	}
}